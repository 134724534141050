<template>
  <div class="autocomplete-wrapper">
    <div class="input" :class="{ error: error?.isError }">
      <input
        :value="modelValue"
        class="underlying-input"
        :class="{ disabled: isDisabled }"
        type="text"
        :placeholder="placeholder"
        :disabled="isDisabled"
        @input="event => setData((event.target as HTMLInputElement).value)"
        @blur="event => emit('on-blur', (event.target as HTMLInputElement).value)"
      />

      <Icon
        v-if="!isDisabled"
        class="clear"
        name="close"
        width="14px"
        height="14px"
        stroke="var(--color-grey-500)"
        @click="setData('')"
      />
    </div>
    <div v-if="error?.isError" class="error-message">
      {{ error.text }}
    </div>
    <div ref="autocompleteMenuRef" class="autocomplete-menu">
      <div v-if="!isLoading" class="list">
        <div
          v-for="(o, i) in options"
          :key="o.slug"
          ref="itemRefs"
          class="autocomplete-item"
          :class="{ selected: i === selectedIndex }"
          @click.stop="setOption(o)"
        >
          {{ o.name }}
        </div>
      </div>
      <div v-else class="loader" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SlugItem } from '@/lib/types/models/common';

const { modelValue, options } = defineProps<{
  modelValue?: string;
  options?: SlugItem[];
  isLoading?: boolean;
  placeholder: string;
  error?: { isError: boolean; text: string };
  isDisabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'on-name-update' | 'on-blur', value: string): void;
  (e: 'on-option-choice', value: SlugItem): void;
}>();

const selectedIndex = ref(0);

const itemRefs = ref([]);

const currentItemRef = computed(() => itemRefs.value[selectedIndex.value]);

const autocompleteMenuRef = ref();

const isItemNotVisible = computed(() => {
  if (currentItemRef.value && autocompleteMenuRef.value) {
    const { top, bottom } = (currentItemRef.value as HTMLElement).getBoundingClientRect();
    const { top: menuTop, bottom: menuBottom } = (
      autocompleteMenuRef.value as HTMLElement
    ).getBoundingClientRect();

    return top < menuTop || bottom > menuBottom;
  }

  return true;
});

function scrollIfNeeded(): void {
  if (currentItemRef.value && isItemNotVisible.value) {
    (currentItemRef.value as HTMLElement).scrollIntoView({
      block: 'nearest',
      inline: 'nearest'
    });
  }
}

function setData(payload: string): void {
  emit('on-name-update', payload);
}

function setOption(payload: SlugItem): void {
  emit('on-option-choice', payload);
}

onMounted(() => {
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keydown', handleKeyUp);
  document.addEventListener('keydown', handleKeyEnter);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyDown);
  document.removeEventListener('keydown', handleKeyUp);
  document.removeEventListener('keydown', handleKeyEnter);
});

function handleKeyDown(e: KeyboardEvent): void {
  if (e.key === 'ArrowDown') {
    if (options && !(selectedIndex.value + 1 > options.length - 1)) selectedIndex.value += 1;
    scrollIfNeeded();
  }
}

function handleKeyUp(e: KeyboardEvent): void {
  if (e.key === 'ArrowUp') {
    if (options && !(selectedIndex.value - 1 < 0)) selectedIndex.value -= 1;
    scrollIfNeeded();
  }
}

function handleKeyEnter(e: KeyboardEvent): void {
  if (e.key === 'Enter' && selectedOption.value) {
    setOption(selectedOption.value);

    e.preventDefault();
  }
}

const selectedOption = computed(() => {
  return options ? options[selectedIndex.value] : undefined;
});
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/border-radius.scss';
@use '$/misc.scss';
@use '$/shadows.scss';

.autocomplete-wrapper {
  flex-direction: column;
  width: 100%;
  max-width: 286px;

  .clear {
    cursor: pointer;

    position: absolute;
    z-index: 20;
    top: calc(50% - 14px / 2);
    right: 12px;
  }

  .input {
    position: relative;

    padding: 0;

    // No idea why but this is working and not padding: 12px 0 12px 12px;
    padding-right: 0;

    font-size: 20px;

    border: solid 1px colors.$grey-500;
    border-radius: border-radius.$small;

    &.error {
      border-color: red;
    }

    .disabled {
      color: colors.$grey-900;
      background-color: colors.$grey-300;
    }

    .underlying-input {
      width: 100%;
      height: 100%;
      padding: 12px;

      font-size: 20px;

      border: none;
      border-radius: border-radius.$small;
      outline: none;

      &:focus {
        border: none;
        outline: none;
      }

      @media screen and (width <= 768px) {
        padding: 0;
        font-size: 16px;
      }
    }
  }

  .autocomplete-menu {
    position: absolute;
    z-index: 10;

    overflow-y: auto;
    flex-direction: column;

    width: 100%;
    max-width: 312px;
    max-height: 280px;
    margin-top: 54px;

    background-color: white;
    border-radius: border-radius.$small;
    box-shadow: shadows.$page;

    .list {
      flex-direction: column;
      gap: 8px;
      align-items: center;
      border-radius: border-radius.$small;

      .autocomplete-item {
        cursor: pointer;

        width: 100%;
        padding: 24px;

        border-radius: border-radius.$small;

        transition: 0.2s;

        &:hover,
        &.selected {
          background-color: colors.$grey-100;
        }
      }
    }

    .loader {
      @include misc.loader();

      align-self: center;
      width: 32px;
      height: 32px;
      margin: 16px;
    }
  }

  .error-message {
    gap: 12px;
    font-size: 16px;
    color: red;
  }
}

@media (width <= 868px) {
  .autocomplete-wrapper {
    max-width: 100% !important;

    .input {
      height: 38px;
      font-size: 16px;

      .underlying-input {
        padding: 0 8px;
      }
    }

    .autocomplete-menu {
      max-width: 100%;
    }
  }
}
</style>
